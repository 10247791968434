import { NetworkStatus } from '@apollo/client'
import { Api, SharedUtils } from '@walter/shared'
import { Form, UserUtils, convertToOptionValue, t, useDebouncedState } from '@walter/shared-web'
import { useAtom, useSetAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import uniqBy from 'lodash/uniqBy'
import * as React from 'react'
import { SelectorProps } from './types'
import { useSelectorProjectId } from './useSelectorProjectId'

export type ResidentSelectorResident = NonNullable<
  NonNullable<Api.ResidentsForSearchQuery['getResidentsForManager']['edges']>[number]['node']
>

type DefaultValue = { id: string; firstName?: string | null; lastName?: string | null }

type Props<Model, OptionValue> = SelectorProps<Model, OptionValue> & {
  defaultValue?: DefaultValue | DefaultValue[]
  matchFrom?: 'start' | 'any'
  withUnit?: boolean
  filter?: (unit: ResidentSelectorResident) => boolean
  availableOptionsRef?: React.MutableRefObject<ResidentSelectorResident[]>
  selectedUnitIds?: string[]
}

export function ResidentFormSelector<Model, OptionValue>(props: Props<Model, OptionValue>) {
  const [textFilter, setTextFilter] = React.useState<string>('')
  const [debouncedTextFilter, forceUpdateDebouncedTextFilter] = useDebouncedState(textFilter, 750)
  const { fetchMoreResidents, residents, isLoading, hasMore } = useResidentsQuery<Model, OptionValue>(
    debouncedTextFilter,
    props,
  )

  const onAdditionalChange = React.useCallback(
    (selected: Parameters<NonNullable<typeof props.additionalOnChange>>[0]) => {
      if (textFilter) {
        forceUpdateDebouncedTextFilter('')
      }
      if (props?.additionalOnChange) {
        props.additionalOnChange(selected)
      }
    },
    [textFilter, props.additionalOnChange],
  )

  const defaultValue = React.useMemo(() => {
    return props?.defaultValue
      ? Array.isArray(props.defaultValue)
        ? convertToOptionValue(props.defaultValue, props.defaultValue, (user) => {
            let value = UserUtils.getUserName(user as DefaultValue)
            if (props.withUnit) {
              value = SharedUtils.getUserNameAndUnitAndProject(user)
            }
            return value
          })
        : {
            value: { id: props.defaultValue.id },
            label: SharedUtils.getUserNameAndUnitAndProject(props.defaultValue),
          }
      : undefined
  }, [props.defaultValue])

  const options = React.useMemo(() => {
    const validResidents = props.filter ? residents.filter(props.filter) : residents
    if (props.availableOptionsRef?.current) {
      props.availableOptionsRef.current = validResidents
    }
    return validResidents.map((resident) => {
      let label = UserUtils.getUserName(resident)
      if (props.withUnit) {
        label = SharedUtils.getUserNameAndUnitAndProject(resident as SharedUtils.UserNameAndUnitType)
      }

      return {
        value: { id: resident.id },
        label,
      }
    })
  }, [residents, props.filter])

  return (
    <Form.Select<Model, OptionValue>
      {...props}
      options={options}
      isLoading={isLoading || textFilter !== debouncedTextFilter}
      inputValue={hasMore || textFilter ? textFilter : undefined}
      onInputChange={hasMore || textFilter ? setTextFilter : undefined}
      additionalOnChange={onAdditionalChange}
      onMenuScrollToBottom={fetchMoreResidents}
      value={defaultValue}
      noOptionsText={t('no-tenants')}
      matchFrom={props.matchFrom ?? 'start'}
      multiValueHref={(item) => `residents/${(item.value as DefaultValue).id}/details?show-back=true`}
    />
  )
}

const projectIdsAtom = atomWithStorage<string[]>('projectIdsAtomResidentSelector', [])
export const accumulatedResidentsAtom = atomWithStorage<ResidentSelectorResident[]>('accumulatedResidentsAtom', [])

function useResidentsQuery<Model, OptionValue>(textFilter: string, props: Props<Model, OptionValue>) {
  const projectId = useSelectorProjectId()
  const setProjectIds = useSetAtom(projectIdsAtom)
  const [accumulated, setAccumulated] = useAtom(accumulatedResidentsAtom)

  const {
    data: { getResidentsForManager: { edges = [], pageInfo = {}, totalCount = 0 } = {} } = {},
    loading: loadingResidents,
    fetchMore,
    networkStatus,
  } = Api.useResidentsForSearchQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      projectIds: props.projectIds ? props.projectIds : [projectId],
      first: 24,
      textFilter,
      properties: props.selectedUnitIds,
    },
    onCompleted(data) {
      const nodes = data.getResidentsForManager.edges?.map((edge) => edge.node) ?? []
      const defaultValue: ResidentSelectorResident[] = SharedUtils.convertToArray(props.defaultValue)
      setAccumulated((prev) => {
        return uniqBy([...prev, ...nodes, ...defaultValue], 'id').filter((resident) => resident?.id?.length > 0)
      })
    },
  })

  React.useEffect(() => {
    const ids = props.projectIds ? props.projectIds : [projectId]
    setProjectIds((prevProjectIds) => {
      if (JSON.stringify(ids) !== JSON.stringify(prevProjectIds)) {
        setAccumulated(edges?.map((edge) => edge.node) ?? [])
        return ids
      }
      return prevProjectIds
    })
  }, [edges, props.projectIds, projectId])

  const fetchMoreResidents = React.useCallback(async () => {
    if ((edges?.length || 0) < totalCount && networkStatus !== NetworkStatus.setVariables) {
      try {
        await fetchMore({
          variables: {
            after: pageInfo.endCursor,
          },
        })
      } catch (err) {
        console.error('[ResidentSelector/useResidentsQuery]', 'Error fetching more', err)
      }
    }
  }, [pageInfo?.endCursor, edges?.length, totalCount])

  return {
    hasMore: (edges?.length || 0) < totalCount,
    residents: accumulated,
    fetchMoreResidents,
    isLoading:
      loadingResidents || networkStatus === NetworkStatus.fetchMore || networkStatus === NetworkStatus.setVariables,
  }
}
