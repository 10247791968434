import { Api } from '@walter/shared'
import { NavBuilding } from '@walter/shared-web'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import AppContext from '../../contexts/App'
import useProjectTotalPendingActions from '../../hooks/useProjectTotalPendingActions'

type BuildingNavProps = {
  project: NonNullable<NonNullable<Api.GetMeManagerWebQuery['me']['managingCompany']>['projects']>[number]
  currentProjectIdPathname: string | null
  isMainBuilding: boolean
  dataTip?: string
}

export default function BuildingNav({ project, currentProjectIdPathname, isMainBuilding, dataTip }: BuildingNavProps) {
  const navigate = useNavigate()

  const { moduleSlug } = React.useContext(AppContext)

  const totalPendingActions = useProjectTotalPendingActions(project?.id)

  function handleBuildingClick() {
    if (!moduleSlug) {
      navigate(`/${project.id}`)
    } else {
      navigate(`/${project.id}/${moduleSlug}`)
    }
  }

  return (
    <NavBuilding
      dataTestId={project?.nameInitials ?? ''}
      slug={project?.id}
      handleClick={handleBuildingClick}
      name={project?.name ?? ''}
      isActive={project?.id === currentProjectIdPathname}
      initials={project?.nameInitials ?? ''}
      logo={project?.squareLogo?.url}
      notifications={totalPendingActions}
      isMainBuilding={isMainBuilding}
      dataTip={dataTip}
    />
  )
}
