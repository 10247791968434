import { Api } from '@walter/shared'
import React from 'react'
import { useLocation } from 'react-router-dom'
import AuthContext from './Auth'

type ManagingCompany = NonNullable<Api.GetMeManagerWebQuery['me']['managingCompany']>
type Projects = NonNullable<ManagingCompany['projects']>

export type ManagingCompanyProjectCache = Map<string, Projects[number]>

type AppContextType = {
  moduleSlug: string
  isAllProjects: boolean
  currentManagingCompanyProjects: Projects
  currentManagingCompanyProjectIds: string[]
  currentManagingCompanyProjectsMap: ManagingCompanyProjectCache
}

const AppContext = React.createContext({} as AppContextType)

export default AppContext

export function AppProvider({ children }: { children: React.ReactNode }) {
  const { pathname } = useLocation()

  const { currentUser } = React.useContext(AuthContext)

  const [, projectId, moduleSlug] = React.useMemo(() => {
    return pathname.split('/')
  }, [pathname])

  const isAllProjects = React.useMemo(() => {
    return !projectId || projectId === 'all'
  }, [projectId])

  const currentManagingCompanyProjects = React.useMemo(() => {
    if (!currentUser?.managingCompany?.projects) return []
    return currentUser.managingCompany.projects
      .filter((project) =>
        currentUser.managingCompanyRole
          ? currentUser.managingCompanyRole.projects.some((p) => p.id === project.id)
          : true,
      )
      .sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))
  }, [currentUser])

  const currentManagingCompanyProjectsMap = React.useMemo(() => {
    const map = new Map()
    for (const project of currentManagingCompanyProjects) {
      map.set(project.id, project)
    }
    return map
  }, [currentManagingCompanyProjects])

  const currentManagingCompanyProjectIds = React.useMemo(() => {
    return currentManagingCompanyProjects?.map((p) => p.id)
  }, [currentManagingCompanyProjects])

  const value = {
    moduleSlug,
    isAllProjects,
    currentManagingCompanyProjects,
    currentManagingCompanyProjectIds,
    currentManagingCompanyProjectsMap,
  }

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}
