import { ApolloClient, ApolloLink, FieldPolicy, from, InMemoryCache } from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { ApolloUtils, Config } from '@walter/shared'
import 'cross-fetch/polyfill'
import { captureError } from './logger'
import { relayStylePagination } from '@apollo/client/utilities'
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common'

const retrylink = new RetryLink({
  attempts: {
    max: 3,
    retryIf: (error, operation) => {
      console.log('error', error)
      // Caused by 502 errors we have ATM
      if (error?.message === 'Unexpected token < in JSON at position 0') {
        // eslint-disable-next-line no-console
        console.debug(`Retrying ${operation.operationName} because it failed`)

        return true
      }

      return false
    },
  },
})

const httpLink = new BatchHttpLink({
  uri: Config.apiEndpoint,
})

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      captureError(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`)
    })
  }
})

const link = ApolloLink.from([retrylink, errorLink, httpLink])

type ExistingType = { [key: string]: string }

function customFetchPolicies(keyArgs = ['where']): FieldPolicy {
  return {
    keyArgs,

    // While args.cursor may still be important for requesting
    // a given page, it no longer has any role to play in the
    // merge function.
    merge(existing: ExistingType, incoming: any[], { readField }: { readField: ReadFieldFunction }) {
      const merged = { ...existing }
      incoming.forEach((item) => {
        const key = readField('id', item)
        if (typeof key === 'string' || typeof key === 'number') {
          merged[key] = item
        }
      })
      return merged
    },

    // Return all items stored so far, to avoid ambiguities
    // about the order of the items.
    read(existing: ExistingType) {
      return existing && Object.values(existing)
    },
  }
}

function customFetchObjectPolicy(keyArgs = ['where']): FieldPolicy {
  return {
    keyArgs,
    merge(existing, incoming, { mergeObjects }) {
      return mergeObjects(existing, incoming)
    },
    read(existing: ExistingType) {
      return existing
    },
  }
}

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        reservations: customFetchPolicies(['projectId']),
        tasks: customFetchPolicies(),
        getPostsForResident: customFetchPolicies(['projectId']),
        getOtherResidentsForResident: relayStylePagination(['propertyId']),
        taskCategories: customFetchPolicies(),
        remotes: customFetchPolicies(),
        managingCompanyRoles: customFetchPolicies(),
        accessKeys: customFetchPolicies(),
        segments: customFetchPolicies(),
        ads: customFetchPolicies(['orderBy', 'where', 'search']),
        project: customFetchObjectPolicy(),
        amenities: customFetchPolicies(),
        rules: customFetchPolicies(),
        taskStatuses: customFetchPolicies(),
        contacts: customFetchPolicies(),
        packages: customFetchPolicies(),
        eventsV2: customFetchPolicies(['projectId', 'managingCompanyId', 'minDate', 'maxDate']),
        openResidentTasks: customFetchPolicies(['propertyId']),
        closedResidentTasks: customFetchPolicies(['propertyId']),
        getPostsForManager: relayStylePagination(['projectIds', 'sorting', 'textFilter']),
        getProperties: relayStylePagination([
          'projectIds',
          'sorting',
          'textFilter',
          'projectIdSubBuilding',
          'residentIds',
          'subBuildingProjectId',
        ]),
        getResidentsForManager: relayStylePagination([
          'projectIds',
          'projectsBoardMember',
          'sorting',
          'textFilter',
          'typeFilter',
          'residentIdsFilter',
          'hasInstalledAppFilter',
          'hasReceivedInvitationFilter',
          'activeFilter',
          'textFilterKeys',
          'segments',
          'properties',
        ]),
        getResidentsForManagerV2: relayStylePagination([
          'projectIds',
          'projectsBoardMember',
          'sorting',
          'textFilter',
          'typeFilter',
          'residentIdsFilter',
          'hasInstalledAppFilter',
          'hasReceivedInvitationFilter',
          'textFilterKeys',
          'segments',
          'properties',
          'projectIdSubBuilding',
          'onlyTargetedProjectIds',
        ]),
        getFaqItemsForManager: relayStylePagination(['projects', 'textFilter', 'type']),
        getContactsForManager: relayStylePagination(['projectIds', 'orderBy', 'textFilter', 'isPrivate']),
        getRulesForManager: relayStylePagination(['projectIds']),
        getManagers: relayStylePagination(['managingCompanyId', 'projectIds']),
        accountStatementsForPropertyResident: relayStylePagination(['propertyId']),
        parkingsV2: relayStylePagination(['projectId', 'textFilter', 'managingCompanyId']),
        lockersV2: relayStylePagination(['projectId', 'textFilter', 'managingCompanyId']),
        accessKeysV2: relayStylePagination(['projectId', 'textFilter']),
        remotesV2: relayStylePagination(['projectId', 'textFilter']),
        adsV2: relayStylePagination(['projectIds', 'textFilter', 'orderBy']),
        notificationsV2: relayStylePagination(['postId', 'orderBy', 'textFilter']),
        notificationsForUserV2: relayStylePagination(['userId', 'projectIds', 'textFilter']),
        getFaqItemsForResident: relayStylePagination(['projects', 'textFilter']),
      },
    },
  },
})

const apolloContext = setContext(async (_, { headers }) => {
  const { token, refreshToken } = localStorage

  const sessionStatus = ApolloUtils.getApolloSessionRefreshStatus(token, refreshToken)

  if (sessionStatus === ApolloUtils.SESSION_REFRESH_STATUS_TYPE.SHOULD_REFRESH) {
    try {
      const newTokenResult = await fetch(`${Config.restEndPoint}/auth/refreshToken`, {
        headers: {
          authorization: `Bearer ${refreshToken}`,
        },
        method: 'GET',
      })

      const newTokenJson = await newTokenResult?.json()

      if (!newTokenJson?.token) {
        throw new Error('Unhandled error')
      }

      const newToken = newTokenJson.token
      const newRefreshToken = newTokenJson.refreshToken
      localStorage.setItem('token', newToken)
      localStorage.setItem('refreshToken', newRefreshToken)

      return {
        headers: {
          ...headers,
          authorization: newToken,
        },
      }
    } catch (error) {
      captureError(`Error refresh session ${JSON.stringify(error)}`)
    }
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null,
    },
  }
})

export function init() {
  return new ApolloClient({
    cache,
    link: from([apolloContext, link]),
    credentials: 'include',
  })
}
