import { Api, useManagerConversations, useManagerUnreadMessagesCount } from '@walter/shared'
import * as React from 'react'
import { useProjectId } from '../hooks/useProjectId'
import AppContext from './App'

type IConcersationsContext = ReturnType<typeof useManagerConversations> &
  ReturnType<typeof useManagerUnreadMessagesCount>

const ConversationsContext = React.createContext({} as IConcersationsContext)

export function ConversationsProvider({ children }: { children: React.ReactNode }) {
  const { currentManagingCompanyProjects } = React.useContext(AppContext)
  const projectId = useProjectId()
  const { data: { projectsBuildingComplex } = {}, loading: loadingBuildingComplex } =
    Api.useProjectsBuildingComplexQuery({
      notifyOnNetworkStatusChange: true,
      variables: {
        projectId,
      },
    })

  const projects = React.useMemo(() => {
    if (loadingBuildingComplex) {
      return currentManagingCompanyProjects
        .filter((project) => project.id === projectId)
        .map((project) => ({
          id: project.id,
          name: project.name ?? '',
        }))
    }
    return projectsBuildingComplex?.map((project) => ({ id: project.id, name: project.name ?? '' })) ?? []
  }, [projectsBuildingComplex, loadingBuildingComplex, projectId])

  const managerConversations = useManagerConversations({
    allowedProjects: currentManagingCompanyProjects as Parameters<typeof useManagerConversations>[0]['allowedProjects'],
    selectedProjects: projects ?? [],
  })

  const managerUnreadMessagesCount = useManagerUnreadMessagesCount(projectId === 'all' ? undefined : projectId)

  return (
    <ConversationsContext.Provider value={{ ...managerConversations, ...managerUnreadMessagesCount }}>
      {children}
    </ConversationsContext.Provider>
  )
}

export function useConversations() {
  return React.useContext(ConversationsContext)
}
