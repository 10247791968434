import { t } from '@walter/shared-web'

export function getBuildingComplexDataTip(
  projects: { name?: string | null | undefined; isMainProject?: boolean | null | undefined }[],
) {
  return t('data-tip-building-complex', {
    mainBuilding: '\n' + projects.find((project) => project.isMainProject)?.name + '\n',
    secondaryBuildings: projects
      .filter((project) => {
        return !project.isMainProject
      })
      .sort((a, b) => a.name?.localeCompare(b.name ?? '') ?? 0)
      .reduce((total, project) => (total += project.name + '\n'), ''),
  })
}
