import { SharedUtils } from '@walter/shared'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { t } from '../../utils'
import { Icon } from '../Icon'
import { MultiProjects, MultiProjectsProps } from '../MultiProjects'
import { Pill } from '../Pill'
import { PillGroup } from '../PillGroup'
import { ResourceItem } from '../ResourceItem'
import { FolderType } from './helpers'

type FolderProps = {
  folder: FolderType
  numberOfFilesInIt: number
  onClick: () => void
  preferredLanguage?: string
  handleClickOnEdit?: () => void
  isShared?: boolean
}

export function Folder({
  folder,
  onClick,
  handleClickOnEdit,
  numberOfFilesInIt,
  isShared,
  preferredLanguage,
}: FolderProps) {
  const { projectId } = useParams<{ projectId: string }>()

  const secondaryAction = React.useMemo(() => {
    if (handleClickOnEdit) {
      return {
        disabled: isShared && projectId !== 'all',
        label: t('edit'),
        onClick: handleClickOnEdit,
      }
    }
    return null
  }, [handleClickOnEdit])

  const Description = React.useMemo(() => {
    // Don't show anything if segments isn't specified. (Valid when using this component in resident-web for example)
    if (!folder.segments) {
      return <></>
    }

    if (folder.segments.length > 0) {
      return (
        <PillGroup dataTestId="PillGroup_Folder_Item">
          {folder.segments.map((segment) => (
            <Pill
              dataTestId="Pill_Folder_Item"
              key={segment.id}
              text={SharedUtils.getTitle(segment, preferredLanguage) ?? ''}
            />
          ))}
        </PillGroup>
      )
    }

    return <Pill dataTestId="Pill_Folder_Item" text={t('no-group-restriction')} />
  }, [folder])

  return (
    <ResourceItem
      name={`${folder.name}${numberOfFilesInIt > 0 ? ` (${numberOfFilesInIt})` : ``}`}
      handleClick={onClick}
    >
      <ResourceItem.FigureWrap type="list" className="text-muted-foreground">
        {isShared ? <Icon icon="shared-folder" /> : <Icon icon="folder" />}
      </ResourceItem.FigureWrap>
      <ResourceItem.Main type="list">
        <ResourceItem.Name>{`${folder.name}${
          numberOfFilesInIt > 0 ? ` (${numberOfFilesInIt})` : ``
        }`}</ResourceItem.Name>
        <ResourceItem.Description>{Description}</ResourceItem.Description>
      </ResourceItem.Main>
      <ResourceItem.Extra>
        {projectId === 'all' || isShared ? (
          <MultiProjects
            projects={
              (folder.projects.map((project, index) => ({
                name: folder.projects[index].name ?? '',
                label: project.name,
                logo: project.squareLogo?.url,
                id: project.id,
              })) as MultiProjectsProps['projects']) || []
            }
          />
        ) : null}
      </ResourceItem.Extra>
      <ResourceItem.Actions secondaryAction={secondaryAction} />
    </ResourceItem>
  )
}
