import { ApolloQueryResult, NetworkStatus } from '@apollo/client'
import { Api, SharedUtils } from '@walter/shared'
import { UserUtils } from '@walter/shared-web'
import React from 'react'
import { useProjectId } from '../hooks/useProjectId'
import { t } from '../utils/i18n'
import AppContext from './App'
import AuthContext from './Auth'

export type ManagingCompanyContextType = {
  managingCompany?: Api.GetDetailedManagingCompanyManagerWebQuery['managingCompany']
  refetchDetailedManagingCompany: (
    variables?: Partial<Api.GetDetailedManagingCompanyManagerWebQueryVariables>,
  ) => Promise<ApolloQueryResult<Api.GetDetailedManagingCompanyManagerWebQuery>>
  loadingDetailedManagingCompany: boolean
  managers: NonNullable<Api.ManagersQuery['getManagers']['edges']>[number]['node'][]
  loadingManagers: boolean
  managingCompanyTaskStatuses?: Api.GetTaskStatusesForManagingCompanyManagerWebQuery['taskStatuses']
  managingCompanyTaskCategories: Api.GetTaskCategoriesForManagingCompanyManagerWebQuery['taskCategories']
  projectOptions: {
    label: string | null | undefined
    value: string
  }[]
  managingCompanyId?: string
  myId?: string
  assigneeOptions: ReturnType<typeof useAssigneeOptions>
  statusOptions: ReturnType<typeof useTaskStatusOptions>
  categoryOptions: ReturnType<typeof useCategoryOptions>
  completedListStatus?: Api.GetTaskStatusesForManagingCompanyManagerWebQuery['taskStatuses'][number]
}

const ManagingCompanyContext = React.createContext({} as ManagingCompanyContextType)

export default ManagingCompanyContext

export function ManagingCompanyProvider({ children }: { children: React.ReactNode }) {
  const { currentManagingCompanyProjectIds } = React.useContext(AppContext)
  const projectId = useProjectId()
  const { currentUser } = React.useContext(AuthContext)

  const projectIds = projectId != null && projectId != 'all' ? [projectId] : currentManagingCompanyProjectIds

  const { data: { taskCategories = [] } = {}, loading: loadingTaskCategories } =
    Api.useGetTaskCategoriesForManagingCompanyManagerWebQuery({
      variables: {
        where: {
          managingCompanyId: {
            equals: currentUser?.managingCompany?.id,
          },
        },
      },
    })

  const {
    data: { managingCompany } = {},
    loading: loadingDetailedManagingCompany,
    refetch: refetchDetailedManagingCompany,
  } = Api.useGetDetailedManagingCompanyManagerWebQuery({
    skip: !currentUser || !currentUser?.managingCompany?.id,
    variables: {
      where: {
        id: currentUser?.managingCompany?.id as string,
      },
    },
  })

  const {
    data: { getManagers: { edges: managerEdges = [] } = {} } = {},
    networkStatus,
    loading: loadingManagers,
  } = Api.useManagersQuery({
    notifyOnNetworkStatusChange: true,
    skip: projectIds?.length === 0 || !currentUser?.managingCompany?.id,
    variables: {
      managingCompanyId: currentUser?.managingCompany?.id as string,
      projectIds,
    },
  })

  const managers = React.useMemo(() => {
    return managerEdges?.map(({ node }) => node) ?? []
  }, [managerEdges])

  // Separated a different query since we also use it in the settings page in managing company where we can create task statuses
  // Maybe could have use GET_TASK_OPTIONS_MANAGER_WEB_QUERY in managing company task statuses settings thought...
  const { data: { taskStatuses = [] } = {}, loading: loadingTaskStatuses } =
    Api.useGetTaskStatusesForManagingCompanyManagerWebQuery({
      variables: {
        orderBy: [
          {
            orderPosition: Api.SortOrder.Asc,
          },
        ],
        where: {
          managingCompanyId: {
            equals: currentUser?.managingCompany?.id,
          },
        },
      },
    })

  const categoryOptions = useCategoryOptions(taskCategories)
  const statusOptions = useTaskStatusOptions(taskStatuses)
  const assigneeOptions = useAssigneeOptions(managers)
  const projectOptions = useProjectOptions()
  const completedListStatus = React.useMemo(() => {
    return taskStatuses.find(({ actAsComplete }) => !!actAsComplete)
  }, [taskStatuses])

  const value = {
    managingCompany,
    refetchDetailedManagingCompany,
    loadingDetailedManagingCompany,
    managers,
    loadingManagers:
      !loadingManagers || networkStatus === NetworkStatus.setVariables || networkStatus === NetworkStatus.fetchMore,
    managingCompanyTaskCategories: taskCategories,
    loadingManagingCompanyTaskCategories: loadingTaskCategories,
    managingCompanyTaskStatuses: taskStatuses,
    loadingManagingCompanyTaskStatuses: loadingTaskStatuses,
    assigneeOptions,
    statusOptions,
    categoryOptions,
    projectOptions,
    myId: currentUser?.id,
    managingCompanyId: currentUser?.managingCompany?.id,
    completedListStatus: completedListStatus,
  }

  return <ManagingCompanyContext.Provider value={value}>{children}</ManagingCompanyContext.Provider>
}

function useAssigneeOptions(managers: NonNullable<Api.ManagersQuery['getManagers']['edges']>[number]['node'][]) {
  return React.useMemo(() => {
    return (
      managers.map(({ id, firstName, lastName, ...rest }) => ({
        value: id,
        label: UserUtils.getUserName({ firstName, lastName }),
        id,
        firstName,
        lastName,
        ...rest,
      })) || []
    )
  }, [managers])
}

function useTaskStatusOptions(taskStatuses: Api.GetTaskStatusesForManagingCompanyManagerWebQuery['taskStatuses']) {
  const { currentUser } = React.useContext(AuthContext)
  return React.useMemo(() => {
    return taskStatuses.map((taskStatus) => {
      const title = SharedUtils.getTitle(taskStatus, currentUser?.preferedLanguage)
      return {
        value: taskStatus.id,
        label: title,
        title,
        ...taskStatus,
      }
    })
  }, [taskStatuses])
}

function useCategoryOptions(taskCategories: Api.GetTaskCategoriesForManagingCompanyManagerWebQuery['taskCategories']) {
  const { currentUser } = React.useContext(AuthContext)
  return React.useMemo(() => {
    return taskCategories.map((taskCategory) => {
      const title = SharedUtils.getTitle(taskCategory, currentUser?.preferedLanguage)
      return {
        value: taskCategory.id,
        label: title,
        id: taskCategory.id,
        title: title,
      }
    })
  }, [taskCategories])
}

function useProjectOptions() {
  const { currentManagingCompanyProjects } = React.useContext(AppContext)
  const projectId = useProjectId()
  const { data: { projectsBuildingComplex } = {}, loading: loadingBuildingComplex } =
    Api.useProjectsBuildingComplexQuery({
      notifyOnNetworkStatusChange: true,
      variables: {
        projectId,
      },
      skip: projectId === 'all',
    })

  return React.useMemo(() => {
    if (projectId === 'all') {
      return [
        { label: t('tasks:all-projects'), value: 'all' },
        ...currentManagingCompanyProjects.map(({ id, name }) => ({ value: id, label: name })),
      ]
    }
    return [
      { label: t('tasks:all-projects'), value: `buildingComplex` },
      ...(projectsBuildingComplex?.map(({ id, name }) => ({ value: id, label: name })) ?? []),
    ]
  }, [currentManagingCompanyProjects, loadingBuildingComplex, projectsBuildingComplex])
}
