import { Colors } from '@walter/shared'
import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import styled from 'styled-components'
import { animationCurve, animationTime, borderRadius } from '../../../styles/global'
import { fontSizes } from '../../../styles/typography'

const QuillWrapper = styled.div`
  .quill {
    .ql-toolbar.ql-snow {
      border: 1px solid ${Colors.borderColor};
      border-top-left-radius: ${borderRadius};
      border-top-right-radius: ${borderRadius};
      background-color: ${Colors.offWhite};
    }

    .ql-container.ql-snow {
      font-size: ${fontSizes.regular};
      /* 320 px because right now we use it in post and event and it's often big text that goes there */
      min-height: 320px;
      background-color: ${Colors.white};
      border: 1px solid ${Colors.borderColor};
      border-bottom-left-radius: ${borderRadius};
      border-bottom-right-radius: ${borderRadius};
      transition: background-color ${animationTime} ${animationCurve}, border-color ${animationTime} ${animationCurve},
        color ${animationTime} ${animationCurve}, box-shadow ${animationTime} ${animationCurve};

      .ql-editor {
        min-height: 320px;
        color: ${Colors.grey};
      }
      .ql-editor:focus {
        box-shadow: inset 0 0 0 1px ${Colors.primaryColor}, 0 0 0 1px ${Colors.primaryColor};
        background-color: ${Colors.white};
      }
    }
  }
`
type RichTextareaProps = {
  name: string
  disabled?: boolean
  placeholder?: string
  onValueChange?: (value: string) => void
}

export const RichTextarea = ({ name, disabled, placeholder, onValueChange }: RichTextareaProps) => {
  const {
    formState: { isSubmitting },
    register,
    setValue,
    getValues,
  } = useFormContext()

  React.useEffect(() => {
    register(name)
  }, [register, name])

  const handleChange = (value: string) => {
    const transformedValue = transformOrderedToUnorderedListTags(value)
    setValue(name, transformedValue, { shouldDirty: true })
    onValueChange?.(transformedValue)
  }

  function initQuillDefaultValue(ref: ReactQuill | null) {
    if (ref) {
      const defaultValue = getValues(name)
      if (ref.editor?.root.innerHTML === '<p><br></p>' && defaultValue) {
        const transformedValue = transformUnorderedToOrderedListTags(getValues(name))
        ref.editor.root.innerHTML = transformedValue
      }
    }
  }

  return (
    <QuillWrapper data-test-id="RichTextArea" data-cy="rich-text-area">
      <ReactQuill
        ref={initQuillDefaultValue}
        theme="snow"
        placeholder={placeholder}
        readOnly={isSubmitting || disabled}
        onChange={handleChange}
        defaultValue={getValues(name)}
      />
    </QuillWrapper>
  )
}

// Fixes bullet list issue: https://github.com/slab/quill/issues/2614
const transformOrderedToUnorderedListTags = (html: string) => {
  const div = document.createElement('div')
  div.innerHTML = html

  // Find all <li> elements with 'data-list="bullet"' and transform <ol></ol> to <ul></ul>
  div.querySelectorAll('li[data-list="bullet"]').forEach((li) => {
    // Get the closest parent <ol>
    const parentOl = li.closest('ol')
    if (parentOl) {
      // Create a new <ul>
      const ul = document.createElement('ul')
      // Transfer the <ol>'s content to <ul>
      ul.innerHTML = parentOl.innerHTML
      // Replace <ol> with <ul>
      parentOl.replaceWith(ul)
    }
  })

  return div.innerHTML
}

// Needed to display the correct list style in the editor
// Otherwise, the lists will not be shown
const transformUnorderedToOrderedListTags = (html: string) => {
  const div = document.createElement('div')
  div.innerHTML = html

  // Find all <li> elements with 'data-list="bullet"' and transform <ol></ol> to <ul></ul>
  div.querySelectorAll('li[data-list="bullet"]').forEach((li) => {
    // Get the closest parent <ol>
    const parentUl = li.closest('ul')
    if (parentUl) {
      // Create a new <ul>
      const ol = document.createElement('ol')
      // Transfer the <ol>'s content to <ul>
      ol.innerHTML = parentUl.innerHTML
      // Replace <ol> with <ul>
      parentUl.replaceWith(ol)
    }
  })

  return div.innerHTML
}
