import { NetworkStatus } from '@apollo/client'
import { Api, SharedUtils } from '@walter/shared'
import { Form, convertToOptionValue, t, useDebouncedState } from '@walter/shared-web'
import { useAtom, useSetAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import uniqBy from 'lodash/uniqBy'
import * as React from 'react'
import { SelectorProps } from './types'
import { useSelectorProjectId } from './useSelectorProjectId'

type DefaultValue = { id: string; address?: { apartmentNumber?: string | null } | null }

type Props<Model, OptionValue> = SelectorProps<Model, OptionValue> & {
  defaultValue?: DefaultValue | DefaultValue[]
  filter?: (unit: UnitSelectorUnit) => boolean
  availableOptionsRef?: React.MutableRefObject<UnitSelectorUnit[]>
  selectedResidentIds?: string[]
}

export function UnitSelector<Model, OptionValue>(props: Props<Model, OptionValue>) {
  const [textFilter, setTextFilter] = React.useState<string>('')
  const [debouncedTextFilter, forceUpdateDebouncedTextFilter] = useDebouncedState(textFilter, 750)
  const { fetchMoreUnits, units, isLoading, hasMore } = useUnitsQuery(debouncedTextFilter, props)

  const onAdditionalChange = React.useCallback(
    (selected: Parameters<NonNullable<typeof props.additionalOnChange>>[0]) => {
      if (textFilter) {
        forceUpdateDebouncedTextFilter('')
      }
      if (props?.additionalOnChange) {
        props.additionalOnChange(selected)
      }
    },
    [textFilter, props.additionalOnChange],
  )

  const defaultValue = React.useMemo(() => {
    return props?.defaultValue
      ? Array.isArray(props.defaultValue)
        ? convertToOptionValue(
            props.defaultValue,
            props.defaultValue,
            (unit) => (unit as DefaultValue)?.address?.apartmentNumber ?? t('unknown'),
          )
        : {
            value: { id: props.defaultValue.id },
            label: props.defaultValue?.address?.apartmentNumber ?? t('unknown'),
          }
      : undefined
  }, [props.defaultValue])

  const options = React.useMemo(() => {
    const validUnits = props.filter ? units.filter(props.filter) : units
    if (props.availableOptionsRef?.current) {
      props.availableOptionsRef.current = validUnits
    }
    return validUnits.map((unit) => {
      return {
        value: { id: unit.id },
        label: unit?.address?.apartmentNumber
          ? unit?.address?.apartmentNumber + ' - ' + unit?.building?.project?.name
          : '',
      }
    })
  }, [units, props.filter])

  return (
    <Form.Select<Model, OptionValue>
      {...props}
      options={options}
      isLoading={isLoading || textFilter !== debouncedTextFilter}
      inputValue={hasMore || textFilter ? textFilter : undefined}
      onInputChange={hasMore || textFilter ? setTextFilter : undefined}
      additionalOnChange={onAdditionalChange}
      onMenuScrollToBottom={fetchMoreUnits}
      value={defaultValue}
      noOptionsText={t('no-units')}
      multiValueHref={(item) => `units/${(item.value as DefaultValue).id}/details?show-back=true`}
    />
  )
}

export type UnitSelectorUnit = NonNullable<
  Api.PropertiesDetailedPaginatedQuery['getProperties']['edges']
>[number]['node']

const projectIdsAtom = atomWithStorage<string[]>('projectIdsAtomUnitSelector', [])
export const accumulatedUnitsAtom = atomWithStorage<UnitSelectorUnit[]>('accumulatedUnitsAtom', [])

function useUnitsQuery<Model, OptionValue>(textFilter: string, props: Props<Model, OptionValue>) {
  const projectId = useSelectorProjectId()
  const setProjectIds = useSetAtom(projectIdsAtom)
  const [accumulated, setAccumulated] = useAtom(accumulatedUnitsAtom)

  const {
    data: { getProperties: { edges = [], pageInfo = {}, totalCount = 0 } = {} } = {},
    loading: loadingProperties,
    fetchMore,
    networkStatus,
  } = Api.usePropertiesDetailedPaginatedQuery({
    notifyOnNetworkStatusChange: true,
    skip: projectId === 'all' && !props.projectIds?.length,
    variables: {
      projectIds: props.projectIds ? props.projectIds : [projectId],
      first: 24,
      textFilter,
      residentIds: props.selectedResidentIds,
    },
    onCompleted(data) {
      const nodes = data.getProperties.edges?.map((edge) => edge.node) ?? []
      const defaultValue: UnitSelectorUnit[] = SharedUtils.convertToArray(props.defaultValue)
      setAccumulated((prev) => {
        return uniqBy([...prev, ...nodes, ...defaultValue], 'id')
          .filter((unit) => unit?.id?.length > 0)
          .sort((a, b) => {
            return (a?.address?.apartmentNumber ?? '').localeCompare(b?.address?.apartmentNumber ?? '') === 0
              ? (a?.building?.project?.name ?? '').localeCompare(b?.building?.project?.name ?? '')
              : (a?.address?.apartmentNumber ?? '').localeCompare(b?.address?.apartmentNumber ?? '')
          })
      })
    },
  })

  React.useEffect(() => {
    const ids = props.projectIds ? props.projectIds : [projectId]
    setProjectIds((prevProjectIds) => {
      if (JSON.stringify(ids) !== JSON.stringify(prevProjectIds)) {
        setAccumulated(edges?.map((edge) => edge.node) ?? [])
        return ids
      }
      return prevProjectIds
    })
  }, [edges, props.projectIds, projectId])

  const fetchMoreUnits = React.useCallback(async () => {
    if ((edges?.length || 0) < totalCount && networkStatus !== NetworkStatus.setVariables) {
      try {
        await fetchMore({
          variables: {
            after: pageInfo.endCursor,
          },
        })
      } catch (err) {
        console.error('[UnitSelector/useUnitsQuery]', 'Error fetching more', err)
      }
    }
  }, [pageInfo?.endCursor, edges?.length, totalCount])

  return {
    hasMore: (edges?.length || 0) < totalCount,
    units: accumulated,
    fetchMoreUnits,
    isLoading:
      loadingProperties || networkStatus === NetworkStatus.fetchMore || networkStatus === NetworkStatus.setVariables,
  }
}
