import * as React from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import { Colors } from '@walter/shared'
import { borderRadius, cover, square } from '../../styles/global'
import { fontSizes, fontWeights } from '../../styles/typography'

const Container = styled.div<{ hasBorder: string; size?: string; hasLogo: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.accent};
  color: ${Colors.white};
  border-radius: ${borderRadius};
  flex-shrink: 0;
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.minuscule};
  padding-left: 5px;
  padding-right: 5px;
  min-width: 24px;
  min-height: 24px;
  ${(props) =>
    props.hasLogo &&
    css`
      ${square('24px')};
    `}

  ${(props) =>
    props.hasBorder &&
    css`
      position: relative;

      &:after {
        content: '';
        box-shadow: inset 0 0 0 1px ${rgba(Colors.black, 0.15)};
        border-radius: ${borderRadius};
        ${cover('absolute')};
      }
    `}

  ${(props) =>
    props.size === 'medium' &&
    css`
      font-size: ${fontSizes.tiny};
      ${square('32px')};
    `}
  
  ${(props) =>
    props.size === 'large' &&
    css`
      font-size: ${fontSizes.regular};
      ${square('48px')};
    `}
`

type BuildingLogoProps = {
  name: string
  logo?: any
  label?: string
  size?: string
  className?: string
}

export const BuildingLogo = ({ name, logo, label, size, className }: BuildingLogoProps) => {
  const [error, setError] = React.useState(false)
  return (
    <Container
      data-test-id="Building_Logo"
      data-tip={label != name ? name : undefined}
      hasBorder={logo}
      size={size}
      className={className}
      hasLogo={!!logo}
    >
      {logo && !error && (
        <img
          src={logo}
          alt={`${name} logo`}
          className="absolute inset-0 object-cover rounded"
          onError={() => {
            setError(true)
          }}
        />
      )}
      {(!logo || error) && <span>{label || name?.charAt(0) || 'N/A'}</span>}
    </Container>
  )
}
